import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NatureIcon from '@mui/icons-material/Nature';
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { useState } from 'react';
import { IProgramNameType } from '../../../../models/IProgramNameType';
import maStyles from '../../ManagementAreaDetail.module.css';
import ProgramDetail from '../ProgramDetail/ProgramDetail';
import styles from './ProgramListItem.module.css';

interface IExpandCardProps {
  program: IProgramNameType;
  managementAreaId: number;
}

export default function ProgramListItem(props: IExpandCardProps) {
  const [open, setOpen] = useState(false);
  const PROGRAM_TYPES = {
    0: (
      <Avatar className={styles.avatarCyclic}>
        {' '}
        <FlipCameraAndroidIcon />
      </Avatar>
    ),
    1: (
      <Avatar className={styles.avatarOther}>
        <NatureIcon />
      </Avatar>
    ),
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Box className={maStyles.itemHeader}>
          <Typography variant="subtitle2">Program name</Typography>
        </Box>
        <Box className={maStyles.itemContent}>
          <Typography variant="subtitle1">{props.program.name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box sx={{ marginTop: '16px' }}>
          <Tooltip title={props.program.type}>{PROGRAM_TYPES[(props.program.type === 'Cyclic' ? 0 : 1) as keyof typeof PROGRAM_TYPES]}</Tooltip>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <CardHeader
          action={
            <IconButton onClick={() => setOpen(!open)} aria-label="expand" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        ></CardHeader>
      </Grid>
      <Grid item xs={12}>
        <ProgramDetail program={props.program} open={open} managementAreaId={props.managementAreaId} />
      </Grid>
    </Grid>
  );
}
