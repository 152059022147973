import { Box, Grid, LinearProgress, Typography } from '@mui/material';

interface IProgramDetailProgressProps {
  color: string;
  title: string;
  value: number;
  progress: number;
}

export default function TaskProgress(props: IProgramDetailProgressProps) {
  return (
    <Grid container justifyContent="center">
      <Box sx={{ width: '80%', marginTop: '20px' }}>
        <Grid container>
          <Grid item>
            <Typography variant="subtitle1">{props.title}</Typography>
          </Grid>
          <Grid item xs>
            <Grid container direction="row-reverse">
              <Grid item>
                <Typography variant="subtitle1">{props.value}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <LinearProgress
          sx={{
            marginTop: '10px',
            backgroundColor: '#383838',
            height: '6px',
            borderRadius: '8px',
            '& .MuiLinearProgress-bar': {
              backgroundColor: props.color,
              borderRadius: '8px',
            },
          }}
          variant="determinate"
          value={props.progress}
        />
      </Box>
    </Grid>
  );
}
