import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { IManagementAreaDetail } from '../../models/IManagementAreaDetail';
import styles from './ManagementAreaDetail.module.css';
import settings from '../../config';
import { fetchWithAuthorisationHeader } from '../../services/AuthenticationService';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { Chip, Divider, IconButton } from '@mui/material';
import { AssetChart } from './AssetChart/AssetChart';
import { AssetResults } from './AssetResults/AssetResults';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ProgramList from './ProgramList/ProgramList/ProgramList';

interface IManagementAreaDetailProps {
  managementAreaId: number;
  cycleId: string;
}

export function ManagementAreaDetail(props: IManagementAreaDetailProps) {
  const [managementAreaDetail, setManagementAreaDetail] = useState<IManagementAreaDetail | null>(null);
  const [showSpanList, setShowSpanList] = useState(true);

  useEffect(() => {
    async function getManagementAreaDetail() {
      try {
        var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'managementArea/detail/' + props.managementAreaId);

        setManagementAreaDetail(response.data as IManagementAreaDetail);
      } catch (e) {
        console.log(e);
        setManagementAreaDetail(null);
      }
    }
    setManagementAreaDetail(null);
    if (props.managementAreaId > 0) {
      getManagementAreaDetail();
    }
  }, [props.managementAreaId]);

  return (
    <div style={{ height: '100%' }}>
      <Box display="flex" style={{ textAlign: 'left', fontWeight: 400, height: '100%' }}>
        {managementAreaDetail == null && (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            <LinearProgress style={{ width: '260px', color: 'red', marginBottom: '8px' }} />
          </Box>
        )}
        {managementAreaDetail != null && (
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Box className={styles.itemHeader}>
                <Typography variant="subtitle1">Management Area</Typography>
              </Box>
              <Box className={styles.itemContent}>
                <Typography variant="h5">{managementAreaDetail.managementAreaName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box sx={{ marginTop: '30px' }}>
                <Chip size="small" label={managementAreaDetail.status} color="secondary" />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={styles.itemHeader}>
                <Typography variant="subtitle2">Region</Typography>
              </Box>
              <Box className={styles.itemContent}>
                <Typography variant="subtitle1">{managementAreaDetail.regionName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={styles.itemHeader}>
                <Typography variant="subtitle2">Type</Typography>
              </Box>
              <Box className={styles.itemContent}>
                <Typography variant="subtitle1">{managementAreaDetail.type}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={styles.itemHeader}>
                <Typography variant="subtitle2">Spans</Typography>
              </Box>
              <Box className={styles.itemContent}>
                <Typography variant="subtitle1">{managementAreaDetail.assetCount}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider light={true} />
            </Grid>
            <Grid item xs={12}>
              <ProgramList managementAreaId={props.managementAreaId} />
            </Grid>
            <Grid item xs={12}>
              <Box className={styles.itemHeader}>
                <Typography variant="subtitle2">Span completion across all programs</Typography>
              </Box>
              <AssetChart
                completedAssets={managementAreaDetail.completedAssetCount}
                inProgressAssets={managementAreaDetail.assetCount - managementAreaDetail.completedAssetCount}
                title={managementAreaDetail.completedAssetCount + ' spans complete'}
                aspectRatio={3}
              />
            </Grid>
            <Grid item xs={11}>
              <Box className={styles.itemHeader}>
                <Typography variant="subtitle2">Span List</Typography>
              </Box>
            </Grid>
            <Grid item xs={1} alignSelf="center" justifySelf="flex-end">
              <Box>
                <IconButton onClick={(e: any) => setShowSpanList(!showSpanList)} style={{ marginLeft: 8, height: 17, width: 17 }}>
                  {showSpanList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
            </Grid>
            {showSpanList && (
              <Grid item xs={12}>
                <Box className={styles.itemContent}>
                  <AssetResults cycleId={props.cycleId} managementAreaId={props.managementAreaId} totalAssetCount={managementAreaDetail.assetCount}></AssetResults>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </div>
  );
}
