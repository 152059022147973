import React, { useState, useCallback, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';

import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { postWithAuthorisationHeader } from "../../../services/AuthenticationService";
import settings from '../../../config';

import styles from './ManagementAreaSearchResults.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { IManagementAreaSearchCreateResponse } from '../../../models/IManagementAreaSearchCreateResponse';
import { IManagementAreaSearchFetchRequest } from '../../../models/IManagementAreaSearchFetchRequest';
import { IManagementAreaSearchFetchResponse } from '../../../models/IManagementAreaSearchFetchResponse';
import { IManagementArea } from '../../../models/IManagementArea';
import { ManagementAreaSearchResult } from '../ManagementAreaSearchResult/ManagementAreaSearchResult';
import noResultsImage from '../../../images/noresults.png';
import Typography from '@mui/material/Typography';

interface IManagementAreaSearchResultsProps {
    managementAreaSearch: IManagementAreaSearchCreateResponse | null;
    selectedManagementAreaId: number;
    onManagementAreaSelected: Function;
}

interface IFetchCallbackProps {
    pageIndex: number;
}

export function ManagementAreaSearchResults(props: IManagementAreaSearchResultsProps) {
    const pageSize: number = 40;
    const defaultPageIndex: number = 1;
    const defaultSearchArray: IManagementArea[] = [];
    const defaultRenderedSearchResults: JSX.Element[] = [];
    const prevSearchIdRef = useRef('');
    const scrollPos = useRef(0);
    const listRef = useRef(null);
   
    const searchId = getSearchId(props);

    // State
    const [pageIndex, setPageIndex] = useState(defaultPageIndex);
    const [managementAreaSearchResults, setManagementAreaSearchResults] = useState(defaultSearchArray);
    const [loading, setLoading] = useState(false);
    const [renderedSearchResults, setRenderedSearchResults] = useState(defaultRenderedSearchResults);
    const [initialised, setInitialised] = useState(false);
    const prevSearchId = prevSearchIdRef.current;
    const [listHeightSize, setListHeightSize] = useState(500);
    const [selectedManagementAreaId, setSelectedManagementAreaId] = useState(0);

    function getSearchId(props: IManagementAreaSearchResultsProps): string {
        return props != null && props.managementAreaSearch != null && props.managementAreaSearch.searchId != null ? props.managementAreaSearch.searchId : '';
    }

    function selectManagementArea(managementArea: IManagementArea) {
        setRenderedSearchResults(defaultRenderedSearchResults);
        setSelectedManagementAreaId(managementArea.managementAreaId);
        props.onManagementAreaSelected(managementArea);
    }

    let noSearchResults = (<div key={1} style={{ height: '100%' }}>
        {props.managementAreaSearch == null ? '' :
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '80%' }}>
                <img src={noResultsImage} title='No Results' style={{ paddingBottom: '10%', width: '38%' }} />
                <span>
                    <Typography className={styles.noResultsText} variant="subtitle2" gutterBottom>
                        No results found
                    </Typography>
                </span>
                <span>
                    <Typography className={styles.noResultsText} variant="body2" gutterBottom>
                        Try adjusting your search criteria
                    </Typography>
                </span>
            </Box>}
    </div>);

   
    const fetchSearchResults = useCallback(async (cbprops: IFetchCallbackProps) => {
        if (props == null || props.managementAreaSearch == null || props.managementAreaSearch.searchId == null || props.managementAreaSearch.searchId.length === 0)
            return;

        try {
            const searchFetchRequest: IManagementAreaSearchFetchRequest = {
                searchId: props.managementAreaSearch.searchId,
                paginated: true,
                pageIndex: cbprops.pageIndex,
                pageSize: pageSize
            };

            setRenderedSearchResults(defaultRenderedSearchResults);
            let response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "search/fetch", searchFetchRequest);

            if (response.status === 200) {
                let searchResponse = response.data as IManagementAreaSearchFetchResponse;

            if (searchResponse.searchId === props.managementAreaSearch.searchId) {

                    let newLength = managementAreaSearchResults.length + searchResponse.managementAreas.length;
                    setManagementAreaSearchResults(prev => [...prev, ...searchResponse.managementAreas]);

                    setInitialised(true);
                    setLoading(false);
                if (newLength < props.managementAreaSearch.totalManagementAreasCount) {
                    setHasNextPage(true);
                }
                   
                    setIsNextPageLoading(false);
                }
                else {
                    console.log("Search id mismatch");
                }
            }
            else {
                console.log("Failed to get search results. Code = " + response.status);
            }
        }
        catch (e) {
            console.log(e);
        }

    }, [pageIndex, pageSize, props.managementAreaSearch, managementAreaSearchResults]);

    const [hasNextPage, setHasNextPage] = useState(true);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);

    function loadNextPage(startIndex: number, stopIndex: number) {
        setLoading(true);
        setIsNextPageLoading(true);
        let newPageIndex = pageIndex + 1;
        if (!initialised) {
            // Make sure InititeScroll loads from the first index      
            newPageIndex = 1;
        }

        setPageIndex(newPageIndex);

        return fetchSearchResults({ pageIndex: newPageIndex });
    };
    const itemCount = hasNextPage ? managementAreaSearchResults.length + 1 : managementAreaSearchResults.length;
    const isItemLoaded = (index: number) => {

        return !hasNextPage || index < managementAreaSearchResults.length;
    }
    const emptyFunc = (startIndex: number, stopIndex: number) => { };
    let loadMoreItems = isNextPageLoading ? emptyFunc : loadNextPage;

    useEffect(() => {
        setRenderedSearchResults(defaultRenderedSearchResults);
        setSelectedManagementAreaId(props.selectedManagementAreaId);
    }, [props.selectedManagementAreaId]);

    useEffect(() => {
        let doSearch = false;
        let thePageIndex = pageIndex;
     
        if (searchId !== prevSearchId) {
            doSearch = true;
            setRenderedSearchResults(defaultRenderedSearchResults);
            setInitialised(false);
            //The search has changed so reset to defaults
            thePageIndex = defaultPageIndex;
            setPageIndex(thePageIndex);
        }

        //Store the searchid so we can work out when it changes
        prevSearchIdRef.current = searchId;

        if (doSearch && searchId.length > 0) {
            setInitialised(true);
            setManagementAreaSearchResults(defaultSearchArray);
            //dont fetch here let inifinite scroll take care of it
        }

        if (props.managementAreaSearch == null) {
            setManagementAreaSearchResults(defaultSearchArray);
        }

    }, [props.managementAreaSearch]);


    function ManagementAreaRow(rowProps: ListChildComponentProps) {
        let managementArea = managementAreaSearchResults[rowProps.index];
        if (managementArea != null) {
            if (renderedSearchResults[rowProps.index] == null || renderedSearchResults[rowProps.index] == undefined) {

                // Render it if it is not already rendered.
                renderedSearchResults[rowProps.index] =
                  <div key={managementArea.managementAreaId} className={styles.managementAreaSearchResultContainer} style={rowProps.style}>
                    <ManagementAreaSearchResult 
                        managementArea={managementArea}
                        isManagementAreaSelected={managementArea.managementAreaId === selectedManagementAreaId} 
                        onManagementAreaSelected={(managementArea: IManagementArea) => {
                            selectManagementArea(managementArea);
                        }}
                    />
                  </div>
            }

            return renderedSearchResults[rowProps.index];
        }
        else {
            return null;
        }
    }

    let footerText = '';
    let endManagementArea = managementAreaSearchResults.length;
    if (props.managementAreaSearch && endManagementArea > props.managementAreaSearch?.totalManagementAreasCount)
        endManagementArea = props.managementAreaSearch?.totalManagementAreasCount;

    footerText = 'Showing ' + endManagementArea + ' out of ' + props.managementAreaSearch?.totalManagementAreasCount + ' Management Areas';

    let listHeight = listHeightSize;
    if (listRef && listRef.current) {
        let domObj = listRef.current as any;
        if (domObj.clientHeight > 0) {

            if (domObj.clientHeight != listHeightSize)
                setListHeightSize(domObj.clientHeight);

            listHeight = domObj.clientHeight;
        }
    }
    return (
        <Box display="flex" flexDirection="column" className={styles.content} >
            <Box flexGrow={1} >
                <div style={{ height: '100%' }} ref={listRef} >
                    {props.managementAreaSearch && props.managementAreaSearch.totalManagementAreasCount > 0 ?
                        <Box style={{ height: '0px' }} >

                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={itemCount}
                                loadMoreItems={loadMoreItems}
                            >
                                {({ onItemsRendered, ref }) => (
                                    <List
                                        className="List"
                                        height={listHeight}
                                        itemCount={itemCount}
                                        itemSize={74}
                                        width={450}
                                        ref= {ref}
                                        initialScrollOffset={scrollPos != null ? scrollPos.current : 0}
                                        onItemsRendered={onItemsRendered}
                                        onScroll={(e: any) => scrollPos.current = e.scrollOffset}
                                    >
                                        {ManagementAreaRow}
                                    </List>
                                )}
                            </InfiniteLoader>
                        </Box> : noSearchResults}
                </div>
            </Box>
            {(props.managementAreaSearch && props.managementAreaSearch.totalManagementAreasCount > 0) &&
                <Box height={40} className={styles.footerContainer}>
                    <Box display="flex" flexDirection="row" className={styles.footerContent}>
                        <Box flexGrow={1}>
                            <Typography className={styles.footerText} variant="body2" gutterBottom>
                                {footerText}
                            </Typography>
                        </Box>
                        <Box className={styles.loader}>
                            {loading && <CircularProgress disableShrink style={{ 'color': '#F7B500', 'width': '25px', 'height': '25px' }} />}
                        </Box>
                    </Box>
                </Box>}

        </Box>
    );
}
