import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import settings from '../../../../config';

import { postWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import { CompletedManagementAreasWidget } from '../Widgets/CompletedManagementAreasWidget/CompletedManagementAreasWidget';
import { CompliantManagementAreasWidget } from '../Widgets/CompliantManagementAreasWidget/CompliantManagementAreasWidget';
import { InProgressManagementAreasWidget } from '../Widgets/InProgressManagementAreasWidget/InProgressManagementAreasWidget';
import { NotScheduledManagementAreasWidget } from '../Widgets/NotScheduledManagementAreasWidget/NotScheduledManagementAreasWidget';
import { IDashboardFilters } from '../../../../models/IDashboardFilters';
import { IDashboardProgramManagementArea } from '../../../../models/IDashboardProgramManagementArea';
import { SpanPerformanceWidget } from '../Widgets/SpanPerformanceWidget/SpanPerformanceWidget';
import { ICycle } from '../../../../models/ICycle';
import { ManagementAreasSchedulesWidget } from '../Widgets/ManagementAreasSchedulesWidget/ManagementAreasSchedulesWidget';

// To Do: Replace this with filter props 
interface IProps {
    filters: IDashboardFilters;
    chartBackgroundColor: string;
    cycle: ICycle;
}

export function ManagementAreaProgressContainer(props: IProps) {
    const defaultProgramManagementAreas: IDashboardProgramManagementArea[] = [];
    const [programManagementAreas, setProgramManagementAreas] = useState(defaultProgramManagementAreas);

    // Handle when the cycle has changed
    useEffect(() => {
        async function getProgramManagementAreas() {
            try {
                var response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "dashboard/programManagementAreas/", props.filters);
                let responseProgramManagementAreas = response.data as IDashboardProgramManagementArea[];
                setProgramManagementAreas(responseProgramManagementAreas);
            }
            catch (e) {
                console.log('Failed to get program management areas:' + e);
            }
        }
        if (props.filters.cycleId != '0'){
            getProgramManagementAreas();
        }
    }, [props.filters]);

    return <Card sx={{ height: "100%", width: "100%", bgcolor: '#161616',backgroundImage:'none'}}>
                <Grid container direction="column" >
                    <Grid item >
                        <Box  sx={{ borderRadius: '5px', marginBottom: 1 }}>
                            <CompletedManagementAreasWidget programManagementAreas={programManagementAreas} />
                        </Box>
                    </Grid>
                 
                    <Grid item>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box mr={3} sx={{ height: "100%", width: "100%", borderRadius: '5px' }}>
                                    <NotScheduledManagementAreasWidget programManagementAreas={programManagementAreas} programCount={props.filters.programNameIds.length} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ height: "180%", width: "100%", borderRadius: '5px' }}>
                                        <InProgressManagementAreasWidget programManagementAreas={programManagementAreas} />
                                </Box>
                            </Grid>
                            
                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ height: "100%", borderRadius: '5px' }}>
                                    <CompliantManagementAreasWidget programManagementAreas={programManagementAreas} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Box mt={3} sx={{ height: "50%", width: "100%", borderRadius: '5px' }}>
                            <ManagementAreasSchedulesWidget programManagementAreas={programManagementAreas} />
                        </Box>
                    </Grid>             
                    <Grid item>
                        <Box mt={3} sx={{ height: "50%", width: "100%", borderRadius: '5px' }}>
                            <SpanPerformanceWidget programManagemetAreas={programManagementAreas} cycle={props.cycle}/>
                        </Box>
                    </Grid>

                </Grid>
          </Card>
}