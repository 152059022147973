import axios from 'axios';
import settings from '../config';

const axiosApiInstance = axios.create();


export function getAuthorizationData() {

    const authData = localStorage.getItem('aims3d.authorizationData');
    if (authData) {
        let data = JSON.parse(authData);
        return data;
    }
    return null;
}

export function removeAuthorizationData()
{
    localStorage.removeItem('aims3d.authorizationData');
    localStorage.removeItem('cycleId');
    localStorage.removeItem('selectedRegionIds');
    localStorage.removeItem('managementAreaTypes');
    localStorage.removeItem('programNameIds');
}

  export function getTokenClaims() {
      var authData = getAuthorizationData();
      if (authData) {
          var token = authData.token;
          if (token) {
              // Return an array of claims
              return JSON.parse(atob(token.split('.')[1]));
          }
      }

      return null;
  }

  export function getTokenExpiryTime() {
    let claims = getTokenClaims();

    return claims != null ? new Date(parseInt(claims['exp'])*1000) : null;
  }

  export function isUserAuthenticated() {
    try {
        let claims = getTokenClaims();
        if (claims != null) {
            return claims.products.includes("AIMS VAA");
        }
        return false;
    } catch (e) {
        return false;
    }
  }

  export async function fetchWithAuthorisationHeader(url: string) {
    return await axiosApiInstance.get(url);
  }
  
  export async function postWithAuthorisationHeader(url: string, details: any) {
      return await axiosApiInstance.post(url, JSON.stringify(details));
  }

  // Request interceptor for API calls
    axiosApiInstance.interceptors.request.use(
      async (config: any) => {
         var authData = await getAuthorizationData();

     let token = authData != null ? authData.token : '';

      config.headers = { 
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      return config;
    },
    (error: any) => {
      Promise.reject(error)
  });

  //Response interceptor for API calls
  axiosApiInstance.interceptors.response.use((response: any) => {
    return response
  }, async function (error: any) {
          try {
              const originalRequest = error.config;
              if (error.response.status === 401 && !originalRequest._retry) {
                  originalRequest._retry = true;

                  const access_token = await refreshToken();

                  if (access_token == null) {
                      //window.location.href = (settings.AIMS3D_URL as string + (localStorage.getItem('aims3d.vaa.assetid') ? "/#/home?product=AIMSVAA" : ""));
                      //window.location.href = (settings.AIMS3D_URL as string + "/#/home?product=AIMSVAA");
                      return Promise.reject(error);
                  }

                  axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;

                  return axiosApiInstance(originalRequest);
              }
              return Promise.reject(error);
          } catch (e) {
              console.log("ERROR Could not complete API request: " + e);
              return Promise.reject(error);
          }
  });

  export async function refreshToken(){
    try{
        var authData = await getAuthorizationData();

        if (authData) {
            const requestData = 'grant_type=refresh_token&refresh_token=' + authData.refreshToken + '&client_id=' + settings.CLIENT_ID + '&client_secret=' + settings.CLIENT_SECRET;
            var refreshTokenResponse = await axiosApiInstance.post(settings.AIMS3D_API_URL + "token", requestData);
            authData.token = refreshTokenResponse.data.access_token;
            authData.refreshToken = refreshTokenResponse.data.refresh_token;
            localStorage.setItem('aims3d.authorizationData', JSON.stringify(authData));
            return refreshTokenResponse.data.access_token;
        }

        return null;

    } catch (e) {
      console.log("ERROR Could not refresh token: " + e);
      return null;
    }
  }