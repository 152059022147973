import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import maStyles from '../../ManagementAreaDetail.module.css';
import { IProgramNameType } from '../../../../models/IProgramNameType';
import { AssetChart } from '../../AssetChart/AssetChart';
import Collapse from '@mui/material/Collapse';
import TaskProgress from '../TaskProgress/TaskProgress';
import { useEffect, useState } from 'react';
import { IProgramDetail } from '../../../../models/IProgramDetail';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import settings from '../../../../config';
import moment from 'moment';
import { ITaskTypeCount } from '../../../../models/TaskTypeCount';

interface IProgramDetailProps {
  open: boolean;
  program: IProgramNameType;
  managementAreaId: number;
}

export default function ProgramDetail(props: IProgramDetailProps) {
  const [programDetail, setProgramDetail] = useState<IProgramDetail | null>(null);
  const [totalTasks, setTotalTasks] = useState(0);

  useEffect(() => {
    async function getProgramDetail() {
      try {
        var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'Program/programDetail/' + props.program.programId + '/' + props.managementAreaId);
        setProgramDetail(response.data as IProgramDetail);
      } catch (e) {
        console.log(e);
        setProgramDetail(null);
      }
    }

    if (props.program.programId > 0 && props.open) {
      getProgramDetail();
    }
  }, [props.program.programId, props.managementAreaId, props.open]);

  useEffect(() => {
    if (programDetail !== null && programDetail.tasks.length > 0) {
      setTotalTasks(programDetail.tasks.map((task) => task.taskCount).reduce((a, b) => a + b));
    }
  }, [programDetail]);

  const layout = (title: string, value: string, isDate: boolean) => {
    return (
      <>
        <Box className={maStyles.itemHeader}>
          <Typography variant="subtitle2">{title}</Typography>
        </Box>
        <Box className={maStyles.itemContent}>
          <Typography variant="subtitle1">{isDate ? (value === null ? '-' : moment(value).format('DD-MM-YYYY')) : value}</Typography>
        </Box>
      </>
    );
  };

  const getPercentage = (taskCount: number) => {
    return totalTasks > 0 ? (100 * taskCount) / totalTasks : 0;
  };

  return programDetail === null ? (
    <></>
  ) : (
    <Collapse in={props.open} timeout="auto" unmountOnExit>
      <Grid container>
        <Grid item xs={12}>
          {layout('Status', programDetail.status ?? '-', false)}
        </Grid>
        <Grid item xs={6}>
          {layout('Scheduled start date', programDetail.scheduledStartDate, true)}
        </Grid>
        <Grid item xs={6}>
          {layout('Scheduled due date', programDetail.scheduledDueDate, true)}
        </Grid>
        <Grid item xs={6}>
          {layout('Practical completion date', programDetail.practicalCompletionDate, true)}
        </Grid>
        <Grid item xs={6}>
          {layout('Compliance date', programDetail.complianceDate, true)}
        </Grid>
        <Grid item xs={12}>
          <Box className={maStyles.itemHeader}>
            <Typography variant="subtitle2">Span Summary</Typography>
          </Box>
          <AssetChart
            key={props.program.programId}
            completedAssets={programDetail.completedAssetCount}
            inProgressAssets={programDetail.totalAssetCount - programDetail.completedAssetCount}
            title={`${programDetail.completedAssetCount}/${programDetail.totalAssetCount} spans completed`}
            aspectRatio={4}
          />
          <Box className={maStyles.itemHeader}>
            <Typography variant="subtitle2">Summary of Tasks</Typography>
          </Box>
          {programDetail.tasks.length > 0 ? (
            <>
              <Box sx={{ marginTop: '6px', marginBottom: '20px' }}>
                {programDetail.tasks.map((item: ITaskTypeCount, i: number) => (
                  <TaskProgress key={i} color="#9875ff" title={item.taskType} value={item.taskCount} progress={getPercentage(item.taskCount)} />
                ))}
              </Box>
              <Grid container justifyContent="center">
                <Box sx={{ width: '80%', marginBottom: '20px' }}>
                  <Grid container>
                    <Grid item>
                    <Typography variant="subtitle1">Total</Typography>
                    </Grid>
                    <Grid item xs>
                      <Grid container direction="row-reverse">
                        <Grid item>
                          <Typography variant="subtitle1">{totalTasks}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          ) : (
            <Grid container justifyContent="center">
              <Box sx={{ marginBottom: '20px' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box className={maStyles.itemContent}>
                      <Typography variant="subtitle1">No tasks received yet</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Collapse>
  );
}
