import React from 'react';
import Box from '@mui/material/Box';

import styles from './AssetResult.module.css';
import Typography from '@mui/material/Typography';
import { IAsset } from '../../../models/IAsset';
import { Divider, Grid } from '@mui/material';
import { SpanStatus } from '../../../Enum/SpanStatus';


interface IAssetResultProps {
    asset: IAsset;
}

export function AssetResult(props: IAssetResultProps) {
    if (props.asset == null) return null;

    return (
        <Grid container className={styles.container}>
            <Grid container alignItems="center">
                <span className={styles.dot}
                    style={{ backgroundColor: SpanStatus[props.asset.statusAcrossAllPrograms as keyof typeof SpanStatus], borderColor: SpanStatus[props.asset.statusAcrossAllPrograms as keyof typeof SpanStatus] }}>
                </span>
                <Typography variant="subtitle2">{props.asset.externalReferenceId}</Typography>
            </Grid>
            <Grid container direction="column">
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>
        </Grid>
    ); 
}
