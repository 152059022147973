import React from "react";
import { Box, Chip, CircularProgress, Tooltip } from "@mui/material/";
import Grid from "@mui/material/Grid";
import { IManagementArea } from "../../../models/IManagementArea";
import styles from "./ManagementAreaSearchResult.module.css";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Programs } from "./Programs/Programs";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface IManagementAreaSearchResultProps {
    managementArea: IManagementArea;
    isManagementAreaSelected: boolean;
    onManagementAreaSelected: Function;
}

export function ManagementAreaSearchResult(props: IManagementAreaSearchResultProps) {
    if (props.managementArea == null) return null;

    let containerClasses = props.isManagementAreaSelected ? styles.container + ' ' + styles.selected : styles.container;
    
    let managementAreaDetailContainer: JSX.Element;
    let progressPercentage: number = 
        props.managementArea.totalAssetCount > 0 
        ? Math.round(100 * props.managementArea.finalisedAssetCount / props.managementArea.totalAssetCount)
        : 0;

    managementAreaDetailContainer = (<Grid item xs={12}
        className={styles.content}>
        <Grid container spacing={2}>
            <Grid container item xs={7} direction="column">
                <Grid item>
                    <Typography className={styles.bodyText} variant="caption">
                        <Chip
                            className={styles.progressLabel}
                            size="small"
                            variant="filled"                           
                            label={props.managementArea.status || 'In Progress'}
                            style={{ backgroundColor: "#EED99A", color: "#2a2b2c" }}
                        />
                    </Typography>
                    <Typography variant="body2" className={styles.managementAreaLabel}>
                        {props.managementArea.managementAreaName || "---"}
                    </Typography>
                </Grid>
                <Grid item className={styles.assetsDetails}>
                    <Typography variant="caption">
                        {props.managementArea.totalAssetCount || "0"} span{props.managementArea.totalAssetCount !== 1 ? "s" : ""}
                    </Typography>
                </Grid>
                <Grid item className={styles.bottomText}>
                    <Typography variant="caption">
                        <span>{props.managementArea.regionName || "---"}</span>
                        <span>{props.managementArea.type || "---"} </span>
                    </Typography>

                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Box className={styles.programTitle}>
                    <Typography className={styles.bodyText} variant="caption" gutterBottom>
                        Programs
                    </Typography>
                </Box>
                <Box>
                    <Programs programs={props.managementArea.programs}/> 
                </Box>
            </Grid>

            <Grid item xs={1} alignSelf="center" justifySelf="center">
                <Tooltip title={`${props.managementArea.finalisedAssetCount} span${props.managementArea.finalisedAssetCount !== 1 ? "s" : ""} finalised across all programs`} placement="top" arrow>
                    <Box className={styles.progressRingContainer}>
                        <CircularProgress size={45} value={ progressPercentage } variant="determinate" />
                        <Box className={styles.progressRingOverlay}>
                            <Typography variant="caption" sx={{lineHeight:"46px"}}>{progressPercentage}%</Typography>
                        </Box>
                        <Box className={styles.progressRingBackground}></Box>
                    </Box>
                </Tooltip>
            </Grid>

            <Grid item xs={1} alignSelf="center" justifySelf="flex-end">
                <Box marginLeft="10px">
                    <ChevronRightIcon height={28} />
                </Box>
            </Grid>
        </Grid>
    </Grid>);

    return (
        <Grid container className={containerClasses} alignItems="center"
            onClick={() => props.onManagementAreaSelected(props.managementArea)}
        >
            <Grid container direction="column">
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                {managementAreaDetailContainer}
            </Grid>
        </Grid>
    );
}
