import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import settings from '../../../../config';
import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from '../../../../services/AuthenticationService';

import { IDashboardFilters } from '../../../../models/IDashboardFilters';
import { NoAuditManagementAreasWidget } from '../Widgets/NoAuditManagementAreasWidget/NoAuditManagementAreasWidget';
import { IAssetTask } from '../../../../models/IAssetTask';


// To Do: Replace this with filter props 
interface IProps {
    cycleId: string;
    filters: IDashboardFilters;
    chartBackgroundColor:string;
}

export function AuditQualityContainer(props: IProps) {

    const defaultAssetTasks: IAssetTask[] = [];
    const [assetTasks, setAssetTasks] = useState(defaultAssetTasks);
 
    // Handle when the cycle has changed
    async function getAssetTasks() {
        try {
            var response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + "dashboard/assetTasks/", props.filters);
            setAssetTasks(response.data);
        }
        catch (e) {
            console.log('Failed to get span performance values:' + e);
        }
    }

    useEffect(() => {
        if (props.filters.cycleId != '0') {
           getAssetTasks();
        }

    }, [props.filters]);

    return <Card sx={{ height: "100%" }}>
        
            <Box sx={{ height: "100%"}}>
                <Box ml={3} mr={3}>
                    <Grid container direction="column" >
                        <Grid item>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Box mb={3} sx={{ height: "50%", borderRadius: '5px'}}>
                                         <NoAuditManagementAreasWidget assetTasks={assetTasks}/>
                                    </Box>
                                </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
         
          </Card>
}