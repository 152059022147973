import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { IAssetTask } from '../../../../../models/IAssetTask';
import { SimpleDisplayCountWidget } from '../../../Shared/Widgets/SimpleDisplayCountWidget/SimpleDisplayCountWidget';

interface IProps {
    assetTasks: IAssetTask[];
}

export function NoAuditManagementAreasWidget(props: IProps) {

    const topText = 'MAs commenced, no audits';
    const height = 130;
    const width = 164;
    const color = "#47211B";
    const [bottomText, setBottomText] = useState('')
    const [count, setCount] = useState(0);

    const groupBy = (arr: IAssetTask[], key: string | number) => {
        const initialValue = {};
        return arr.reduce((acc: any, cval) => {
            const maIdAttribute = cval[key as keyof typeof cval];
            acc[maIdAttribute as keyof typeof acc]  = [...(acc[maIdAttribute as keyof typeof acc] || []), cval]
            return acc;
        }, initialValue);
    };


    useEffect(() => {

        let noAuditManagementAreaCount = 0;

        if (props.assetTasks.length > 0) {

            //Group by management areas.
            let groupedByManagementArea = groupBy(props.assetTasks, 'managementAreaId');

            //Under each management area, group by the programs
            Object.keys(groupedByManagementArea).forEach(function (key, index) {
                let groupedByProgram = groupBy(groupedByManagementArea[key], 'programId');
                groupedByManagementArea[key] = groupedByProgram;
            });

            Object.keys(groupedByManagementArea).forEach(function (ma, index) {
                for (const program in groupedByManagementArea[ma]) {
                    //Add the management area to the count when it has atleast one commenced program with no audit tasks.
                    let doesAuditTaskExist = groupedByManagementArea[ma][program].some((x: any) => x.assetTaskType == 'Assessment Audit' || x.assetTaskType == 'Treatment Audit');
                    if (!doesAuditTaskExist) {
                        ++noAuditManagementAreaCount;
                        break;
                    }
                }
            });
            setCount(noAuditManagementAreaCount);
            let totalCount = Object.keys(groupedByManagementArea).length;
             setBottomText("Across " + totalCount + " MA" + (totalCount > 1 ? 's' : ''));

        }
        
    }, [props.assetTasks]);

    return <Card sx={{ height: "100%", backgroundImage: 'none' }}>
        <SimpleDisplayCountWidget
            height={height}
            width={width}
            color={color}
            topText={topText}
            count={count}
            bottomText={bottomText} />
    </Card>
}